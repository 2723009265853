<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="row">
        <div class="col-8">
          <p class="dialog-title text-uppercase mb-0">Reported Content</p>
        </div>
        <div class="col-4">
          <img class="dialog-close-button"
               src="../../assets/images/close.png"
               alt="close-button"
               @click="$emit('close')" />
        </div>
      </div>
    </div>

    <div class="dialog-content reported-content"
         style="overflow-y: auto;"
         :style="{height: contentHeightOverride}">
      <div class="text-center">
        <div v-show="notifTypeId === 21">
          <p class="small mb-1">
            <b>This post has been reported.</b>
          </p>
          <p class="small">
            Please review this post and take action. If your post has been
            reported, it may have violated our
            <router-link :to="{ name: 'community-standards' }"
                         target="_blank">
              community standards
            </router-link>.
          </p>
        </div>
        
        <div v-show="notifTypeId === 22">
          <p class="small text-center">
            <b>This post has been hidden.</b>
          </p>
            <p class="small">
              If your post has been hidden, it means that it was flagged by
              users as non-appropriate. Please review our
              <router-link :to="{ name: 'community-standards' }"
                           target="_blank">
                community standards
              </router-link> or choose an action below.
            </p>
        </div>

        <!-- Image -->
        <img class="ucc-image img-fluid"
             :src="media.url"
             alt="user-created-content"
             @click="showLightbox(filteredImage.name)"
             v-show="media.type === 'image'" />

        <!-- Video -->
        <vue-plyr v-show="media.type === 'video'">
          <video
                :src="media.url"
                :style="{height: `${vpHeight}px`}">
            <source :src="media.url" type="video/mp4" size="720">
            <source :src="media.url" type="video/mp4" size="1080">
          </video>
        </vue-plyr>
      </div>

      <!-- Categories -->
      <div class="mt-2 mb-2">
        <b-badge class="mr-1 mb-1 categ-badge"
                 variant="primary"
                 v-for="(category, index) in media.categories"
                 :key="index"
                 v-show="notifTypeId == 22">
          <span v-if="category.category_name">
            {{ category.category_name }}
          </span>
          <b-badge variant="light">{{ category.cnt }}</b-badge>
        </b-badge>
      </div>

      <!-- Reason -->
      <div v-if="notifTypeId == 22">
        <p class="small mb-0">
          <b v-if="media.reasons.length > 1">Reasons:</b>
          <b v-else>Reason:</b>
        </p>
        <div class="reasons">
          <ul class="list">
            <li class="reason"
                v-for="(reason, index) in media.reasons"
                :key="index">
              {{ reason.reason }}
            </li>
          </ul>
        </div>
      </div>

      <!-- Buttons -->
      <div class="text-center mt-2">
        <b-button class="btn-h3-small"
                  type="button"
                  @click="contactAdmin"
                  variant="success"
                  :disabled="media.status == 4">
          Contact H3
        </b-button>
        &nbsp;
        <b-button class="btn-h3-small"
                  type="button"
                  @click="removeMedia(media.uccId)"
                  variant="danger">
          Remove
        </b-button>
      </div>
    </div>

    <lightbox id="mylightbox"
              ref="lightbox"
              :directory="thumbnailDir"
              :filter="galleryFilter"
              :images="filteredImage"
              :timeoutDuration="5000">
    </lightbox>
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  const ContactForm = () => import('@/components/modals/ContactForm.vue');

  export default {
    name   : 'ReportedContent',
    mixins : [
      BreakpointMixin,
    ],
    props : {
      uccId       : String,
      notifTypeId : Number,
    },
    data() {
      return {
        thumbnailDir  : '',
        galleryFilter : 'all',
        colWidth      : 0,
        vpHeight      : 0,
        issueData     : {},
        media         : {},
        filteredImage : [],
      }
    },
    watch : {
      colWidth : function() {
        this.vpHeight = this.colWidth > 0 ? this.colWidth * 0.75 : 0;
      },
    },
    methods : {

      /**
       * Remove Media
       * @param id
       */
      removeMedia(id) {
        this.$http.put('api/issues/status', {
          uccId  : id,
          status : 3,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Removed content successfully!',
          });
          this.$emit('close');
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong.',
          });
        });
      },

      /**
       * Contact Admin
       */
      contactAdmin() {
        this.$emit('close');
        this.$modal.show(ContactForm, {
          email : this.$store.getters['user/user'].email,
          name  : this.$store.getters['user/user'].firstname + ' ' +
            this.$store.getters['user/user'].lastname,
          uccId : this.uccId,
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },

      /**
       * Get Issue Details
       * @param id
       */
      getIssue(id) {
        this.$http.get('api/issue', {
          params : {
            uccId : id,
          },
        }).then(response => {
          this.issueData = response.data.data[0];

          this.media = {
            type       : this.issueData.content[0].type,
            url        : this.issueData.content[0].url,
            thumbnail  : this.issueData.thumbnails[1].image_url,
            uccId      : this.issueData.ucc_id,
            ownderId   : this.issueData.content_owner_id,
            guardianId : this.issueData.guardian_id,
            categories : this.issueData.categories,
            reasons    : this.issueData.reasons,
            status     : this.issueData.content[0].status,
          };

          // for full image view
          const result = [];
          if (this.issueData.content[0].type === 'image') {
            result.push({
              id     : this.issueData.ucc_id,
              name   : this.issueData.thumbnails[1].image_url,
              filter : 'all',
            });
          }
          this.filteredImage = result;
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
      * To view image's whole photo
      */
      showLightbox(imageName) {
        this.$refs.lightbox.show(imageName);
      },

      /**
       * Get Column's Width
       */
      getColumnWidth() {
        this.$nextTick(() => {
          const $colId = this.$el.getElementsByClassName('dialog-content')[0];
          if ($colId)
            this.colWidth = $colId.clientWidth;
        });
      },
    },
    mounted() {
      this.getColumnWidth();
      this.getIssue(this.uccId);
    },
    updated() {
      this.getColumnWidth();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/reported-content";
</style>